.already-have-an-acco {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;

  letter-spacing: 0;
  line-height: normal;
  margin: 0;
}

.text-wrapper-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.text-wrapper-4 {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
