.text-wrapper-6 {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
.forgot-password-link {
  margin-top: 25px;
}
.button-link {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;

  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: -moz-fit-content;
}

.button-link:hover {
  color: #0fb082; /* Hover color */
}

.checkbox {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.frame {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none;
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer;
  width: 190px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
