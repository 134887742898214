.description {
  position: relative;
  padding-bottom: 15px;
}

.headline-text {
  color: #000000;
  font-family: "Space Grotesk", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.subtext {
  color: #7b7b7b;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin: 0;
}
