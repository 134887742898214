/* Settings.css */

/* Style for tabs */
.nav-tabs {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

/* Style for active tab */
.nav-link.active {
  background-color: #74e7c7 !important;
  color: #4f4f4f !important;
  border: 1px solid #74e7c7;
}

.nav-link {
  color: #929292 !important;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

/* Style for tab content */
.tab-content {
  padding: 20px;
  background-color: #fff;
}

/* Additional styling for a more modern look */
.container-settings {
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: block;
  min-height: 95vh;
}

/* Style for Profile tab */
.profile-info {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-info h4 {
  color: #007bff;
  margin-bottom: 5px; /* Adjusted margin for better spacing */
}

.profile-info p {
  color: #555;
  margin-bottom: 0;
  padding-top: 5px;
}

.frame {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none;
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer;
  width: 190px !important;
}

@media (max-width: 768px) {
  /* Adjust styles for screens up to 768px wide */
  .profile-info p {
    /* Specify the styles for the paragraph under profile-info */
    word-wrap: break-word; /* Allow words to break */
    white-space: normal; /* Allow text to wrap */
  }
}

.message {
  color: green;
}

.button-link {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;

  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: -moz-fit-content;
}

.button-link:hover {
  color: #0fb082; /* Hover color */
}
