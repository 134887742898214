.mainnavigation-layout {
  width: 100%;
  margin: 2px;
}

.swiirl-logo-navigation {
  height: 37px;
  left: 0;
  object-fit: cover;
  position: relative;
  top: 7px;
  width: 100px;
}
.img-navigation {
  height: 24px;
  position: relative;
  width: 24px;
}

.frame-navigation {
  align-items: center;
  display: flex;
  gap: 20px;
  left: 0;
  padding: 0px 11px 8px 0px;

  margin-top: 20px;
  width: 180px;
}

.text-wrapper-navigation {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  text-decoration: none;
}

.text-wrapper-navigation-2 {
  color: #929292;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  text-decoration: none;
}
