.reset-password-form {
  margin-top: 110px;
}

.frame-2 {
  margin-top: 110px;

  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none; /* Remove default button border */
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer; /* Add pointer cursor for better UX */
  width: 175px;
}

.frame-2 .text-wrapper {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.back-to-link-div {
  margin-top: 10px;
}
