.limitter {
  width: 100%;
  margin: 0 auto;
}

.login-content {
  width: 440px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 35px 45px 30px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.custom-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
  /*background: linear-gradient(254deg, #50c859d1, #41d0c6);*/
}

.left-section {
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
  padding: 20px;
}

.nav-header {
  width: 100%;
  position: relative;
  padding-bottom: 35px;
}
.logo-holder {
  left: 0;
  /* object-fit: cover; */
  position: relative;
}

.loginform {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.overal-1 {
  display: flex;
  flex-direction: column;
}

.group {
  margin-top: 10px;
  position: relative;
}

.label {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
}

.rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cdcdcd;
  border-radius: 5px;
  height: 35px;
  margin-top: 15px;
  width: 100%;
  padding-right: 40px;
}

.icon-olor-instance {
  position: absolute;
  top: 75%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px !important;
}

.group-2 {
  display: flex;
  align-items: center;
}

.rectangle-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cdcdcd;
  border-radius: 5px;
  height: 20px;
  width: 25px;
  margin-right: 10px;
}

.i-agree-to-all-the-t {
  flex-grow: 1;
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 46px;
  letter-spacing: 0;
  line-height: 1;
}

.span {
  color: #7b7b7b;
}

.text-wrapper-2 {
  color: #003ecb;
}

.frame {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none; /* Remove default button border */
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer; /* Add pointer cursor for better UX */
  width: 125px;
}

.frame .text-wrapper {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

/* right content */

.text-wrapper-5 {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
