/* CardComponent.css */

/* Add your custom styles here */
.custom-card {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  box-shadow: 4px 4px 32px #00000012;
  height: 328px;
  position: relative;
  width: 249px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.css-image-1 {
  object-fit: cover;
  position: relative;

  width: 249px;

  height: 190px;
  max-height: 190px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.image-holder {
  background-color: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.text-wrapper-7 {
  color: rgba(26, 26, 26, 1);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 220px;
}

.text-wrapper-8 {
  color: rgba(26, 26, 26, 1);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 117px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 220px;
}

.line {
  height: 40px;
  left: 98px;
  object-fit: cover;
  position: absolute;
  top: 200px;
  width: 1px;
}

.line-2 {
  height: 1px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 250px;
  width: 210px;
}

.text-wrapper-9 {
  color: rgba(167, 167, 167, 1);
  font-family: "Poppins", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 200px;
}

.text-wrapper-10 {
  color: rgba(167, 167, 167, 1);
  font-family: "Poppins", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 117px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 200px;
}

.card-row-2 {
  height: 57px;
  left: 20px;
  position: absolute;
  top: 234px;
  width: 86px;
}

.text-wrapper-5 {
  color: rgba(26, 26, 26, 1);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
}

.text-wrapper-13 {
  color: rgba(167, 167, 167, 1);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 40px;
}

.rectangle-gallery {
  background-color: rgba(167, 167, 167, 1);
  border-radius: 10px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 35px;
  width: 20px;
}

.send-message {
  cursor: pointer;
  height: 26px;
  left: 169px;
  position: absolute;
  top: 269px;
  width: 26px;
}

.bi-share-fill {
  cursor: pointer;
  height: 14px;
  left: 207px;
  position: absolute;
  top: 275px;
  width: 14px;
}

.rectangle-copy {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cdcdcd;
  border-radius: 5px;
  height: 35px;
  margin-top: 15px;
  width: 75%;
}

.copy-icon {
  margin-left: 8px;
  margin-bottom: 3px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
