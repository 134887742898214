@media only screen and (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .image-container,
  .image-details {
    max-width: 100%; /* Adjust the max-width to take full width */
  }
}

.container-gallery-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  min-height: 95vh;
  padding: 20px; /* Add padding for content within the container */
}

.first-row,
.second-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensure space between items */
  margin-bottom: 20px; /* Add spacing between rows */
}

.image-container {
  width: 100%; /* Take full width */

  align-items: center;
  margin-bottom: 20px;
  margin-top: 35px;
}

.image-container img {
  width: 75%;
  height: auto;
}

.image-details {
  width: 100%; /* Take full width */

  margin-bottom: 20px;
}

.group-nft {
  width: 100%; /* Take full width */
  flex: 1 1 auto; /* Allow flexibility */
  margin-bottom: 20px; /* Add spacing between groups */
  padding: 10px;
  box-sizing: border-box; /* Include padding in width calculation */
}

.group-nft:last-child {
  margin-bottom: 0; /* Remove margin from the last group */
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
}

.rectangle-readonly {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: rgba(226, 226, 226, 1);
}

.content-container {
  width: 100%; /* Take full width */
}
.prompt-container {
  width: 100%; /* Take full width */
  margin-top: 8px;
}

.horizontal-line {
  width: 100%; /* Take full width */
  border-top: 2px solid #ccc;
  margin: 20px 0; /* Add spacing above and below */
}

.button {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none;
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

.grey-box {
  background-color: rgb(248, 248, 248);
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  min-height: 30px;
}

.header-description {
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: rgb(0, 0, 0);
}

.rectangle-description {
  min-height: 465px;
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: rgba(226, 226, 226, 1);
}

.object-list {
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adjust the gap between tags */
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.attribute-values {
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.hr-shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.prompt-input {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cdcdcd;
  border-radius: 5px;
  height: 35px;
  margin-top: 15px;
  width: 100%;
}

.caption-details-image {
  margin-top: 5px;
}

.header-description-image {
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 600;

  color: rgb(0, 0, 0);
}

.error-message-nft {
  background-color: #ffcccc; /* Light red background */
  color: #cc0000; /* Dark red text */
  padding: 10px; /* Padding around the error message */
  border: 1px solid #cc0000; /* Dark red border */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Margin to separate error messages */
}

.loader-middle {
  display: block;
  margin: 0 auto; /* This centers the image horizontally */
  text-align: center;
}
