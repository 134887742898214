.group {
  margin-top: 10px;
  position: relative;
}

.label {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
}

.button-link {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;

  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: -moz-fit-content;
}

.button-link:hover {
  color: #0fb082; /* Hover color */
}

.overal-1 {
  display: flex;
  flex-direction: column;
}

.label {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
}

.rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cdcdcd;
  border-radius: 5px;
  height: 35px;
  margin-top: 15px;
  width: 100%;
  padding-right: 40px;
}

.frame {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none; /* Remove default button border */
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer; /* Add pointer cursor for better UX */
  width: 140px !important;
}

.frame .text-wrapper {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}
