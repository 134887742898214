.overal-1 {
  display: flex;
  flex-direction: column;
}

.group {
  margin-top: 10px;
  position: relative;
}

.label {
  color: #333333;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
}

.rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #cdcdcd;
  border-radius: 5px;
  height: 35px;
  margin-top: 15px;
  width: 100%;
  padding-right: 40px;
}

.frame {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none; /* Remove default button border */
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer; /* Add pointer cursor for better UX */
  width: 140px !important;
}

.frame .text-wrapper {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.deleteicon {
  margin-right: 1rem;
}

.password-errors {
  margin-top: 2rem;
}

/* Add this CSS to your stylesheet */

.button-link {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.button-link:hover {
  color: #0056b3; /* Hover color */
}
.isUserExits-message {
  color: red;
  margin-top: 10px;
}
