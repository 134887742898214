.forgot-password-form {
}

.back-to-login-link {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame-1 {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none; /* Remove default button border */
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer; /* Add pointer cursor for better UX */
  width: 225px;
  margin-top: 35px;
  margin-bottom: 15px;
}

.frame-1 .text-wrapper {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.back-to-link-div {
  margin-top: 10px;
}
