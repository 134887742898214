/* HeaderMenu.css */

.headermenu-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.frame-5 {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px;
}

.frame-6 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.text-wrapper-12 {
  color: #74e7c7;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.avatar {
  background-color: rgba(226, 226, 226, 1);
  border-radius: 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px; /* Adjust the font size as needed */
  min-width: 35px;
  cursor: pointer;
}

.menu-open {
  cursor: pointer;
  width: 30px;
}

.logout {
  cursor: pointer;
}

.headermenu-layout {
  width: 100%;
  margin: 2px;
}
.avatar-dropdown {
  position: absolute;
  background-color: #74e7c7;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: none;
  width: 200px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.avatar-dropdown.open {
  display: block;
  opacity: 1;
}

.avatar:hover .avatar-dropdown {
  display: block;
  opacity: 1;
}

.menu-item {
  margin: 1rem;
}
