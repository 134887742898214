.search-layout {
  width: 100%;
  margin: 0;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}

.overlap-group-wrapper {
  height: 45px;

  margin-top: 10px;

  width: 350px;
}
.container-gallery {
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: block;
  min-height: 95vh;
}

.overlap-group-navigation {
  background-color: rgba(248, 248, 248, 1);
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 350px;
}

.text {
  color: #929292;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  left: 46px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
  width: 300px;
}

.searchtextbox {
  outline: none;
  border: none;
  background: rgba(248, 248, 248, 1);
}

.icon-search-normal {
  height: 21px;
  left: 13px;
  position: absolute;
  top: 11px;
  width: 20px;
}

.min-width-card {
  min-width: 270px !important;
}

/* Default styles */

/* Small devices (576px and up) */
@media (min-width: 576px) {
  /* Styles for small devices and larger */
  .min-value {
    min-width: 500px;
    min-height: 55px;
  }
}

/* Medium devices (768px and up) */
@media (min-width: 768px) {
  /* Styles for medium devices and larger */
  .min-value {
    min-width: 700px;
    min-height: 60px;
  }
}

/* Large devices (992px and up) */
@media (min-width: 992px) {
  /* Styles for large devices and larger */
  .min-value {
    min-width: 900px;
    min-height: 80px;
  }
}

/* Extra large devices (1200px and up) */
@media (min-width: 1200px) {
  /* Styles for extra-large devices and larger */
  .min-value {
    min-width: 1100px;
    min-height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .card-alignment {
    margin-top: 2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.frame-button {
  align-items: center;
  background-color: rgba(116, 231, 199, 1);
  border: none;
  border-radius: 20px;
  gap: 8px;
  padding: 10px 20px;
  cursor: pointer;
  height: 40px;
  margin-left: 1rem;
  width: 125px;
}

.frame-button .text-wrapper {
  color: #000000;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
}

.button-layout {
  width: 100%;
  margin: 0;
  margin-top: top 1rem !important;
  margin-bottom: 2rem !important;
}
